
import { Component, Vue, Prop } from 'vue-property-decorator';

import { BookingsApi } from '@/api/BookingsApi';

import { downloadBlob } from 'download.js';

import b64toBlob from 'b64-to-blob';

import { OrderActivityInterface } from '@/interfaces/Order';

@Component
export default class ActionCreateReport extends Vue {
   @Prop({ type: Object }) form!: OrderActivityInterface;

   base64: string | null = null;

   format: 'pdf' | 'docx' = 'docx';

   modal: boolean = false;

   primary_data: any = null;

   email: string = '';

   emailText: string = '';

   report_code: string = '';

   templates: string[] = [];

   mounted(): void {
      this.$root.$on('header::create-report', this.createReport);
      this.$root.$on('header::supplier-reminders', () => this.orderField('supplier-reminders'));
      this.$root.$on('header::get-templates', () => this.orderField('conversation-template'));
   }

   beforeDestroy(): void {
      this.$root.$off('header::create-report', this.createReport);
      this.$root.$off('header::supplier-reminders', () => this.orderField('supplier-reminders'));
      this.$root.$off('header::get-templates', () => this.orderField('conversation-template'));
   }

   orderField(field: string) {
      this.$store.commit('showLoading');
      BookingsApi.orderOne(this.form.o_id, [field]).then(({ body }) => {
         this.templates = (body as string)
            .split('</div>')
            .map(text => text.replace('<div>', '').trim())
            .filter(text => text.length > 0);
         this.$bvModal.show('templates');
      });
   }

   submit() {
      this.createReport(this.report_code, this.format, this.modal ? 'view' : 'download', this.emailText);
   }

   createReport(report_code: string, format: 'pdf' | 'docx', mode: 'download' | 'view' = 'download', message: string | undefined = undefined) {
      this.$store.commit('showLoading');

      BookingsApi.createReport(this.form.o_id, {
         agent_email: this.email,
         o_agent: this.form.o_agent,
         report_code,
         format,
         mode,
         message,
      }).then(({ base64, filename, primary_data }) => {
         if (filename && base64) {
            this.modal = mode == 'view' && format == 'pdf';
            this.base64 = base64;
            this.format = format;
            this.primary_data = primary_data;
            this.report_code = report_code;
            this.emailText = primary_data?.email_body;

            if (mode == 'download') {
               downloadBlob(filename, b64toBlob(base64, 'application/' + format));
            }
         } else {
            this.$bvToast.toast('Empty data.', {
               solid: true,
               title: 'Warning',
               variant: 'warning',
            });
         }

         if (message) {
            this.cc = '';
            this.subject = '';
            this.emailText = '';

            this.$bvToast.toast('Email has been sent', {
               solid: true,
               title: 'Success!',
               variant: 'success',
            });
         }
      });
   }
}
